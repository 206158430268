/* __next_internal_client_entry_do_not_use__ useCarousel auto */ import { useRef } from "react";
import { useIntersectionObserverRefState } from "./useIntersectionObserverRefState";
export const useCarousel = (param)=>{
    let { hoverButtons = true, gap = [
        4,
        8
    ], pageOffset = [
        [
            24,
            48
        ],
        [
            24,
            48
        ]
    ], size = "m", fixedWidth = true } = param;
    const refEnd = useRef(null);
    const refStart = useRef(null);
    const refParent = useRef(null);
    const [endIsVisible] = useIntersectionObserverRefState({
        deps: [
            pageOffset,
            size
        ],
        ref: refEnd
    });
    const [startIsVisible] = useIntersectionObserverRefState({
        deps: [
            pageOffset,
            size
        ],
        ref: refStart
    });
    const canScrollPrevious = !startIsVisible;
    const canScrollNext = !endIsVisible;
    const scrollNext = ()=>{
        var _refParent_current;
        refParent === null || refParent === void 0 ? void 0 : (_refParent_current = refParent.current) === null || _refParent_current === void 0 ? void 0 : _refParent_current.scrollTo({
            left: refParent.current.scrollLeft + refParent.current.clientWidth,
            behavior: "smooth"
        });
    };
    const scrollPrevious = ()=>{
        var _refParent_current;
        refParent === null || refParent === void 0 ? void 0 : (_refParent_current = refParent.current) === null || _refParent_current === void 0 ? void 0 : _refParent_current.scrollTo({
            left: refParent.current.scrollLeft - refParent.current.clientWidth,
            behavior: "smooth"
        });
    };
    return {
        canScrollNext,
        canScrollPrevious,
        endIsVisible,
        gap,
        hoverButtons,
        pageOffset,
        refEnd,
        refStart,
        refParent,
        scrollNext,
        scrollPrevious,
        size,
        startIsVisible,
        fixedWidth
    };
};
