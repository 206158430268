import { redirectToAuth, redirectToSubscribe, useRequestCollectionFavorite, useRequestFavorite, useRequestMe, useSession } from "@packages/sdk";
export const useFavoriteCollectionAction = (param)=>{
    let { id, has_access } = param;
    const { mutationPost: favoriteCollection } = useRequestCollectionFavorite({
        id
    });
    return useFavoriteAction({
        has_access,
        updateFavorite: favoriteCollection
    });
};
export const useFavoritePrayerAction = (param)=>{
    let { id, has_access } = param;
    const { mutationPost: favoritePrayer } = useRequestFavorite({
        id
    });
    return useFavoriteAction({
        has_access,
        updateFavorite: favoritePrayer
    });
};
const useFavoriteAction = (param)=>{
    let { has_access, updateFavorite } = param;
    const { status } = useSession();
    const { query: user } = useRequestMe();
    return ()=>{
        var _user_data;
        if (status === "authenticated" && ((user === null || user === void 0 ? void 0 : (_user_data = user.data) === null || _user_data === void 0 ? void 0 : _user_data.subscription) || has_access)) updateFavorite.mutate();
        else if (status === "authenticated") redirectToSubscribe();
        else redirectToAuth();
    };
};
