/* __next_internal_client_entry_do_not_use__ useSearchParamState auto */ import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
export const useSearchParamState = (param)=>{
    let { defaultValue, key, multiple, removeFromUrlIfDefaultValue } = param;
    var _searchParams_getAll, _searchParams_get;
    const pathname = usePathname();
    const router = useRouter();
    const searchParams = useSearchParams();
    const [state, setState] = useState(multiple ? ((_searchParams_getAll = searchParams.getAll(key)) === null || _searchParams_getAll === void 0 ? void 0 : _searchParams_getAll.length) > 0 ? searchParams.getAll(key) : defaultValue !== null && defaultValue !== void 0 ? defaultValue : [] : ((_searchParams_get = searchParams.get(key)) === null || _searchParams_get === void 0 ? void 0 : _searchParams_get.length) > 0 ? searchParams.get(key) : defaultValue !== null && defaultValue !== void 0 ? defaultValue : "");
    useEffect(()=>{
        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.delete(key);
        if (!(removeFromUrlIfDefaultValue && JSON.stringify(defaultValue) === JSON.stringify(state))) {
            if (multiple && state.length !== 0) state.forEach((stateValue)=>{
                if (stateValue.length > 0) newSearchParams.append(key, stateValue);
            });
            else if (state.length > 0) newSearchParams.set(key, state);
            else newSearchParams.delete(key);
        }
        newSearchParams.sort();
        router.push("".concat(pathname).concat(newSearchParams.toString() ? "?" + newSearchParams.toString() : ""));
    }, [
        state
    ]);
    return [
        state,
        setState
    ];
};
