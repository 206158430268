/* __next_internal_client_entry_do_not_use__ useScrollToOnLoad auto */ import { useEffect, useRef } from "react";
import { END_OF_BROWSER_ASYNC_QUEUE } from "../lib";
export const useScrollToOnLoad = function() {
    let options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const ref = useRef(null);
    useEffect(()=>{
        if (ref.current) {
            ref.current.scrollIntoView(options);
        } else {
            setTimeout(()=>{
                if (ref.current) {
                    ref.current.scrollIntoView(options);
                }
            }, END_OF_BROWSER_ASYNC_QUEUE);
        }
    }, []);
    return ref;
};
