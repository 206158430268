import { getArtworkMetadata } from "@packages/sdk";
import { useCallback, useMemo } from "react";
import { getContentAlbum, getContentArtist, getContentImage, getContentTitle } from "../lib";
import { useCurrentQueueItem } from "./useCurrentQueueItem";
export const supportsMediaSession = ()=>typeof navigator !== "undefined" && "mediaSession" in navigator;
export const useMediaSessionAPI = (param)=>{
    let { handleEnd, handleReverse, mediaRef } = param;
    const { currentItem } = useCurrentQueueItem();
    const updateMetadata = useCallback(()=>{
        if (currentItem && supportsMediaSession()) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: getContentTitle(currentItem),
                artist: getContentArtist(currentItem),
                album: getContentAlbum(currentItem),
                artwork: getArtworkMetadata(getContentImage(currentItem))
            });
        }
    }, [
        currentItem
    ]);
    const updatePlaybackState = (paused, display)=>{
        if (supportsMediaSession()) {
            navigator.mediaSession.playbackState = display === "hidden" ? "none" : paused ? "paused" : "playing";
        }
    };
    const establishStandardHandlers = function(play, pause, onClose) {
        let noSeek = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : false;
        if (supportsMediaSession()) {
            navigator.mediaSession.setActionHandler("play", ()=>play());
            navigator.mediaSession.setActionHandler("pause", ()=>pause());
            navigator.mediaSession.setActionHandler("stop", ()=>{
                pause(true);
                if (mediaRef.current) mediaRef.current.currentTime = 0;
                onClose();
            });
            if (!noSeek) {
                navigator.mediaSession.setActionHandler("seekto", (param)=>{
                    let { seekTime } = param;
                    if (mediaRef.current) {
                        mediaRef.current.currentTime = seekTime;
                    }
                });
            }
        }
    };
    const destroyStandardHandlers = ()=>{
        if (supportsMediaSession()) {
            navigator.mediaSession.setActionHandler("play", null);
            navigator.mediaSession.setActionHandler("pause", null);
            navigator.mediaSession.setActionHandler("seekto", null);
            navigator.mediaSession.setActionHandler("stop", null);
        }
    };
    const establishSeekHandlers = ()=>{
        if (supportsMediaSession()) {
            navigator.mediaSession.setActionHandler("seekbackward", (param)=>{
                let { seekOffset } = param;
                if (mediaRef.current) {
                    mediaRef.current.currentTime = Math.max(mediaRef.current.currentTime - (seekOffset !== null && seekOffset !== void 0 ? seekOffset : 10), 0);
                }
            });
            navigator.mediaSession.setActionHandler("seekforward", (param)=>{
                let { seekOffset } = param;
                if (mediaRef.current) {
                    mediaRef.current.currentTime = Math.min(mediaRef.current.currentTime + (seekOffset !== null && seekOffset !== void 0 ? seekOffset : 10), mediaRef.current.duration);
                }
            });
        }
    };
    const destroySeekHandlers = ()=>{
        if (supportsMediaSession()) {
            navigator.mediaSession.setActionHandler("seekforward", null);
            navigator.mediaSession.setActionHandler("seekbackward", null);
        }
    };
    const establishTrackHandlers = useCallback(()=>{
        if (supportsMediaSession()) {
            navigator.mediaSession.setActionHandler("nexttrack", ()=>handleEnd(true));
            navigator.mediaSession.setActionHandler("previoustrack", ()=>handleReverse());
        }
    }, [
        handleEnd,
        handleReverse
    ]);
    const destroyTrackHandlers = ()=>{
        if (supportsMediaSession()) {
            navigator.mediaSession.setActionHandler("nexttrack", null);
            navigator.mediaSession.setActionHandler("previoustrack", null);
        }
    };
    const updateDuration = useCallback(()=>{
        if (supportsMediaSession() && currentItem) {
            pushState();
        }
    }, [
        currentItem
    ]);
    const updateSpeed = useCallback(()=>{
        if (supportsMediaSession() && currentItem) {
            pushState();
        }
    }, [
        currentItem
    ]);
    const updateMediaPosition = useCallback(()=>{
        if (supportsMediaSession() && currentItem) {
            pushState();
        }
    }, [
        currentItem
    ]);
    const pushState = ()=>{
        var _mediaRef_current, _mediaRef_current1, _mediaRef_current2, _mediaRef_current3, _mediaRef_current4;
        if (!isNaN((_mediaRef_current = mediaRef.current) === null || _mediaRef_current === void 0 ? void 0 : _mediaRef_current.duration) && ((_mediaRef_current1 = mediaRef.current) === null || _mediaRef_current1 === void 0 ? void 0 : _mediaRef_current1.duration) !== 0 && !isNaN((_mediaRef_current2 = mediaRef.current) === null || _mediaRef_current2 === void 0 ? void 0 : _mediaRef_current2.currentTime) && !isNaN((_mediaRef_current3 = mediaRef.current) === null || _mediaRef_current3 === void 0 ? void 0 : _mediaRef_current3.playbackRate) && ((_mediaRef_current4 = mediaRef.current) === null || _mediaRef_current4 === void 0 ? void 0 : _mediaRef_current4.playbackRate) !== 0) {
            navigator.mediaSession.setPositionState({
                position: Math.min(mediaRef.current.duration, mediaRef.current.currentTime),
                playbackRate: mediaRef.current.playbackRate,
                duration: mediaRef.current.duration
            });
        }
    };
    return useMemo(()=>({
            updateMediaPosition,
            updateMetadata,
            updatePlaybackState,
            establishSeekHandlers,
            establishStandardHandlers,
            establishTrackHandlers,
            destroySeekHandlers,
            destroyStandardHandlers,
            destroyTrackHandlers,
            updateDuration,
            updateSpeed
        }), [
        updateMediaPosition,
        updateMetadata,
        updatePlaybackState,
        establishSeekHandlers,
        establishStandardHandlers,
        establishTrackHandlers,
        destroySeekHandlers,
        destroyStandardHandlers,
        destroyTrackHandlers,
        updateDuration,
        updateSpeed
    ]);
};
