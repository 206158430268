export const getGeolocationStrictConsentPermissions = (param)=>{
    let { geolocation } = param;
    if (geolocation.continent === "EU") return true;
    /** Brazil, South Africa */ if ([
        "BR",
        "ZA"
    ].includes(geolocation.country)) return true;
    /** California */ if ([
        "CA"
    ].includes(geolocation.countryRegion)) return true;
    return false;
};
