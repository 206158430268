/* __next_internal_client_entry_do_not_use__ useDebouncedState auto */ import { useRef, useState } from "react";
export const useDebouncedState = (param)=>{
    let { defaultValue, timeout = 200 } = param;
    const timeoutRef = useRef(null);
    const [state, setState] = useState(defaultValue);
    const resetTimeout = ()=>window.clearTimeout(timeoutRef.current);
    const setStateDebounced = (newState)=>{
        resetTimeout();
        timeoutRef.current = window.setTimeout(()=>{
            setState(newState);
        }, timeout);
    };
    return [
        state,
        setStateDebounced
    ];
};
