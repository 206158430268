/* __next_internal_client_entry_do_not_use__ useIntersectionObserverRefState auto */ import { useEffect, useState } from "react";
export const useIntersectionObserverRefState = (param)=>{
    let { deps, ref } = param;
    const [state, setState] = useState(false);
    useEffect(()=>{
        if (ref["current"]) {
            const observer = new IntersectionObserver((param)=>{
                let [intersection] = param;
                setState(intersection.isIntersecting);
            });
            observer.observe(ref["current"]);
        }
    }, [
        deps,
        ref
    ]);
    return [
        state,
        setState
    ];
};
