/* __next_internal_client_entry_do_not_use__ useTheme auto */ import { useContext, useEffect, useState } from "react";
import { Theme } from "../types";
import { themeContext } from "./themeContext";
export const useTheme = ()=>{
    const { setTheme, theme, themeContainerRef, fontStyle, setFontStyle, letterSpacing, setLetterSpacing, wordSpacing, setWordSpacing } = useContext(themeContext);
    const getCurrentTheme = (theme, isSystemDark)=>{
        return theme === Theme.MATCH ? isSystemDark ? Theme.DARK : Theme.LIGHT : theme;
    };
    const [currentTheme, setCurrentTheme] = useState(Theme.LIGHT);
    const toggleTheme = ()=>setTheme(theme === Theme.DARK ? Theme.LIGHT : Theme.DARK);
    useEffect(()=>{
        if (!window) return;
        const darkModeMatch = window.matchMedia("(prefers-color-scheme: dark)");
        setCurrentTheme(getCurrentTheme(theme, darkModeMatch.matches));
        darkModeMatch.onchange = (event)=>{
            setCurrentTheme(getCurrentTheme(theme, event.matches));
        };
    }, [
        theme
    ]);
    return {
        setTheme,
        theme,
        themeContainerRef,
        currentTheme,
        toggleTheme,
        fontStyle,
        setFontStyle,
        letterSpacing,
        setLetterSpacing,
        wordSpacing,
        setWordSpacing
    };
};
