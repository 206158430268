/* __next_internal_client_entry_do_not_use__ usePlayerSpeedIndex auto */ import { SPEEDS } from "../lib";
import { usePlayer } from "./usePlayer";
export const usePlayerSpeedIndex = ()=>{
    const player = usePlayer();
    var _player_speed;
    const currentSpeed = (_player_speed = player === null || player === void 0 ? void 0 : player.speed) !== null && _player_speed !== void 0 ? _player_speed : 1;
    const currentIdx = SPEEDS.findIndex((s)=>Math.abs(currentSpeed - s) < 0.01);
    return {
        currentIdx,
        player
    };
};
