/* __next_internal_client_entry_do_not_use__ consentContext auto */ import { createContext } from "react";
export const consentContext = createContext({
    ajsAnonymousId: null,
    consent: [],
    isConsenting: false,
    isLoading: true,
    countryCode: null,
    setAjsAnonymousId: ()=>{},
    setConsent: ()=>{},
    /** TODO: remove/improve when ConsentModal/CookieManager are improved */ isConsentModalVisible: false,
    saveConsent: ()=>Promise.resolve(),
    setIsConsentModalVisible: ()=>{}
});
