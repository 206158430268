/* __next_internal_client_entry_do_not_use__ useRefScrollTo auto */ import { useEffect, useRef } from "react";
export const useRefScrollTo = (param)=>{
    let { dependencies, delay = 0, options = {
        behavior: "smooth",
        top: 0
    } } = param;
    const ref = useRef(null);
    useEffect(()=>{
        if (ref.current.scrollTop !== 0) setTimeout(()=>ref.current.scrollTo(options), delay);
    }, dependencies);
    return ref;
};
