import { SECONDS_PER_MINUTE } from "@packages/sdk";
const format = new Intl.NumberFormat(undefined, {
    minimumIntegerDigits: 2,
    maximumFractionDigits: 0
});
export const getMinutesAndSeconds = (param)=>{
    let { time, timeIsMs = false } = param;
    let timeS = time;
    if (timeIsMs) timeS = time / 1000;
    const minutes = format.format(Math.floor(timeS / SECONDS_PER_MINUTE));
    const seconds = format.format(Math.floor(timeS % SECONDS_PER_MINUTE));
    return [
        minutes,
        seconds
    ];
};
