import { getContentArtists } from "../lib";
import { useCurrentQueueItem } from "./useCurrentQueueItem";
export const useCurrentGuide = ()=>{
    const { queue, currentItem } = useCurrentQueueItem();
    const guides = getContentArtists(currentItem);
    return {
        queue,
        currentItem,
        guides
    };
};
