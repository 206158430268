/* __next_internal_client_entry_do_not_use__ useMutationObserver auto */ import { useEffect, useRef } from "react";
const DEFAULT_OPTIONS = {
    attributes: true
};
export const useMutationObserver = function(elRef, callback) {
    let options = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : DEFAULT_OPTIONS;
    const observer = useRef(typeof MutationObserver !== "undefined" ? new MutationObserver(callback) : null);
    const interval = useRef(null);
    const reps = useRef(0);
    useEffect(()=>{
        if (elRef.current) {
            var _observer_current;
            (_observer_current = observer.current) === null || _observer_current === void 0 ? void 0 : _observer_current.observe(elRef.current, options);
        } else {
            interval.current = setInterval(()=>{
                if (reps.current++ > 10 && interval.current) clearInterval(interval.current);
                if (elRef.current) {
                    var _observer_current;
                    if (interval.current) clearInterval(interval.current);
                    (_observer_current = observer.current) === null || _observer_current === void 0 ? void 0 : _observer_current.observe(elRef.current, options);
                }
            }, 50);
        }
        return ()=>{
            var _observer_current;
            reps.current = 0;
            (_observer_current = observer.current) === null || _observer_current === void 0 ? void 0 : _observer_current.disconnect();
        };
    }, [
        observer.current,
        elRef.current,
        options
    ]);
};
