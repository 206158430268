export const CAROUSEL_GRID_SIZES = {
    s: [
        2,
        3,
        4,
        5,
        6,
        7
    ],
    ms: [
        2,
        2,
        3,
        4,
        4,
        4
    ],
    m: [
        1,
        2,
        3,
        4,
        4,
        4
    ],
    l: [
        1,
        2,
        3,
        3,
        3,
        3
    ],
    xl: [
        1,
        1,
        1,
        1,
        1,
        1
    ]
};
