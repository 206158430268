export const getContentId = (content)=>{
    var _content_prayer;
    if ((content === null || content === void 0 ? void 0 : content.type) === "radio_song") {
        return content.radio_song.song_id;
    }
    return content === null || content === void 0 ? void 0 : (_content_prayer = content.prayer) === null || _content_prayer === void 0 ? void 0 : _content_prayer.id;
};
export const getContentTitle = (content)=>{
    var _content_prayer;
    if ((content === null || content === void 0 ? void 0 : content.type) === "radio_song") {
        return content.radio_song.title;
    }
    return content === null || content === void 0 ? void 0 : (_content_prayer = content.prayer) === null || _content_prayer === void 0 ? void 0 : _content_prayer.title;
};
export const getContentImage = (content)=>{
    var _content_collection;
    if ((content === null || content === void 0 ? void 0 : content.type) === "radio_song") {
        return content.radio_station.images;
    }
    return content === null || content === void 0 ? void 0 : (_content_collection = content.collection) === null || _content_collection === void 0 ? void 0 : _content_collection.images;
};
export const getContentDescription = (content)=>{
    var _content_prayer;
    if ((content === null || content === void 0 ? void 0 : content.type) === "radio_song") {
        return content.radio_song.artist_name;
    }
    return content === null || content === void 0 ? void 0 : (_content_prayer = content.prayer) === null || _content_prayer === void 0 ? void 0 : _content_prayer.label_desc;
};
export const getContentShareableLink = (content)=>{
    var _content_prayer;
    if ((content === null || content === void 0 ? void 0 : content.type) === "radio_song") {
        return "https://hallow.com/radio-stations/".concat(content.radio_station.id);
    }
    return content === null || content === void 0 ? void 0 : (_content_prayer = content.prayer) === null || _content_prayer === void 0 ? void 0 : _content_prayer.shareable_url;
};
export const getContentArtist = (content)=>{
    var _content_prayer_guides_find, _content_prayer_guides, _content_prayer;
    if ((content === null || content === void 0 ? void 0 : content.type) === "radio_song") {
        return content.radio_song.artist_name;
    }
    return content === null || content === void 0 ? void 0 : (_content_prayer = content.prayer) === null || _content_prayer === void 0 ? void 0 : (_content_prayer_guides = _content_prayer.guides) === null || _content_prayer_guides === void 0 ? void 0 : (_content_prayer_guides_find = _content_prayer_guides.find((g)=>g.id === content.selected_audio.guide_id)) === null || _content_prayer_guides_find === void 0 ? void 0 : _content_prayer_guides_find.name;
};
export const getContentAlbum = (content)=>{
    var _content_collection;
    if ((content === null || content === void 0 ? void 0 : content.type) === "radio_song") {
        return content.radio_song.album_name;
    }
    return content === null || content === void 0 ? void 0 : (_content_collection = content.collection) === null || _content_collection === void 0 ? void 0 : _content_collection.title;
};
export const getContentAlbumImage = (content)=>{
    var _content_collection;
    if ((content === null || content === void 0 ? void 0 : content.type) === "radio_song") {
        return {
            large: content.radio_song.album_image_url,
            medium: null,
            small: null,
            fallback: content.radio_song.album_image_url
        };
    }
    return content === null || content === void 0 ? void 0 : (_content_collection = content.collection) === null || _content_collection === void 0 ? void 0 : _content_collection.images;
};
export const getContentMetadata = (content)=>{
    var _content_prayer;
    if ((content === null || content === void 0 ? void 0 : content.type) === "radio_song") {
        return content.radio_song.album_name;
    }
    return content === null || content === void 0 ? void 0 : (_content_prayer = content.prayer) === null || _content_prayer === void 0 ? void 0 : _content_prayer.lengths;
};
export const getShareProperties = (content, styleXArray)=>{
    const obj = {};
    if ((content === null || content === void 0 ? void 0 : content.type) === "radio_song") {
        obj.description = content.radio_station.short_desc;
        obj.title = content.radio_station.name;
    } else if (content) {
        obj.description = content.prayer.label_desc;
        obj.title = content.prayer.title;
    }
    const images = getContentImage(content);
    obj.color = images === null || images === void 0 ? void 0 : images.color_hex;
    obj.imgSrc = images === null || images === void 0 ? void 0 : images.fallback;
    obj.link = getContentShareableLink(content);
    obj.styleXArray = styleXArray;
    return obj;
};
export const getContentArtists = (content)=>{
    var _content_prayer;
    if ((content === null || content === void 0 ? void 0 : content.type) === "radio_song") {
        return content.radio_song.artists;
    }
    return content === null || content === void 0 ? void 0 : (_content_prayer = content.prayer) === null || _content_prayer === void 0 ? void 0 : _content_prayer.guides;
};
