/* __next_internal_client_entry_do_not_use__ useDragDropTouch auto */ import { useEffect } from "react";
class DragDropTouch {
    constructor(){
        // Utility functions
        this.shouldHandle = (e)=>{
            var _e_touches;
            return e && !e.defaultPrevented && ((_e_touches = e.touches) === null || _e_touches === void 0 ? void 0 : _e_touches.length) < 2;
        };
        this.shouldHandleMove = (e)=>{
            return !DragDropTouch.IS_PRESS_HOLD_MODE && this.shouldHandle(e);
        };
        this.shouldHandlePressHoldMove = (e)=>{
            var _e_touches;
            return DragDropTouch.IS_PRESS_HOLD_MODE && this.isDragEnabled && (e === null || e === void 0 ? void 0 : (_e_touches = e.touches) === null || _e_touches === void 0 ? void 0 : _e_touches.length) > 0;
        };
        this.shouldCancelPressHoldMove = (e)=>{
            return DragDropTouch.IS_PRESS_HOLD_MODE && !this.isDragEnabled && this.getDelta(e) > DragDropTouch.PRESS_HOLD_MARGIN;
        };
        this.shouldStartDragging = (e)=>{
            const delta = this.getDelta(e);
            return delta > DragDropTouch.THRESHOLD || DragDropTouch.IS_PRESS_HOLD_MODE && delta >= DragDropTouch.PRESS_HOLD_THRESHOLD;
        };
        this.dispatchEvent = (e, type, target, extras)=>{
            if (e && target) {
                var _e_touches;
                const options = {
                    bubbles: true,
                    cancelable: true,
                    button: 0,
                    buttons: 1,
                    which: 1
                };
                if (extras) {
                    options.movementX = extras.movementX;
                    options.movementY = extras.movementY;
                }
                var _e_touches_;
                const touch = (_e_touches_ = e === null || e === void 0 ? void 0 : (_e_touches = e.touches) === null || _e_touches === void 0 ? void 0 : _e_touches[0]) !== null && _e_touches_ !== void 0 ? _e_touches_ : e;
                DragDropTouch.copyProps(e, options, DragDropTouch.KEYBOARD_PROPS);
                DragDropTouch.copyProps(touch, options, DragDropTouch.POINT_PROPS);
                let evt;
                if (type.toLowerCase().startsWith("drag") || type.toLowerCase() === "drop") {
                    options.dataTransfer = this.dataTransfer;
                    evt = new DragEvent(type, options);
                } else {
                    evt = new MouseEvent(type, options);
                }
                // const touch = e?.touches?.[0] ?? e;
                target.dispatchEvent(evt);
                return evt.defaultPrevented;
            }
            return false;
        };
        this.reset = ()=>{
            this.destroyImage();
            this.dragSource = null;
            this.lastTouch = null;
            this.lastTarget = null;
            this.pointDown = null;
            this.isDragEnabled = false;
            this.isDropZone = false;
            this.dataTransfer = new DataTransfer();
            this.lastMovementX = 0;
            this.lastMovementY = 0;
            clearInterval(this.pressHoldInterval);
        };
        this.closestDraggable = (e)=>{
            while(e = e.parentElement){
                if (e.hasAttribute("draggable") && e.getAttribute("draggable")) {
                    return e;
                }
            }
            return null;
        };
        this.getDelta = (e)=>{
            if (DragDropTouch.IS_PRESS_HOLD_MODE && !this.pointDown) {
                return 0;
            }
            const p = this.getPoint(e);
            return Math.abs(p.x - this.pointDown.x) + Math.abs(p.y - this.pointDown.y);
        };
        this.getPoint = (e, page)=>{
            var _e_touches;
            if ((e === null || e === void 0 ? void 0 : (_e_touches = e.touches) === null || _e_touches === void 0 ? void 0 : _e_touches.length) > 0) {
                const touch = e.touches[0];
                return {
                    x: page ? touch.pageX : touch.clientX,
                    y: page ? touch.pageY : touch.clientY
                };
            } else if (e) {
                const me = e;
                return {
                    x: page ? me.pageX : me.clientX,
                    y: page ? me.pageY : me.clientY
                };
            }
        };
        this.getTarget = (e)=>{
            let pt = this.getPoint(e);
            let el = document.elementFromPoint(pt.x, pt.y);
            while(el !== null && getComputedStyle(el).pointerEvents == "none"){
                el = el.parentElement;
            }
            return el;
        };
        this.createImage = (e)=>{
            if (this.img != null) {
                this.destroyImage();
            }
            const src = this.imgCustom || this.dragSource;
            this.img = src.cloneNode(true);
            DragDropTouch.copyStyle(src, this.img);
            this.img.style.top = this.img.style.left = "-9999px";
            if (this.imgCustom === null) {
                const rect = src.getBoundingClientRect();
                const point = this.getPoint(e);
                this.imgOffset = {
                    x: point.x - rect.left,
                    y: point.y - rect.top
                };
                this.img.style.opacity = DragDropTouch.OPACITY.toString();
            }
            this.moveImage(e);
            document.body.appendChild(this.img);
        };
        this.moveImage = (e)=>{
            requestAnimationFrame(()=>{
                if (this.img != null) {
                    const pt = this.getPoint(e, true);
                    const s = this.img.style;
                    s.position = "absolute";
                    s.pointerEvents = "none";
                    s.zIndex = "999999";
                    s.left = Math.round(pt.x - this.imgOffset.x) + "px";
                    s.top = Math.round(pt.y - this.imgOffset.y) + "px";
                }
            });
        };
        this.destroyImage = ()=>{
            var _this_img;
            if ((_this_img = this.img) === null || _this_img === void 0 ? void 0 : _this_img.parentElement) {
                this.img.parentElement.removeChild(this.img);
            }
            this.img = null;
            this.imgCustom = null;
        };
        // Event handlers
        this.touchStart = (e)=>{
            if (this.shouldHandle(e)) {
                if (Date.now() - this.lastClick < DragDropTouch.DBLCLICK) {
                    if (this.dispatchEvent(e, "dblclick", e.target)) {
                        e.preventDefault();
                        this.reset();
                        return;
                    }
                }
                this.reset();
                const src = this.closestDraggable(e.target);
                if (src !== null) {
                    if (!this.dispatchEvent(e, "mousemove", e.target) && !this.dispatchEvent(e, "mousedown", e.target)) {
                        this.dragSource = src;
                        this.pointDown = this.getPoint(e);
                        this.lastTouch = e;
                        e.preventDefault();
                        setTimeout(()=>{
                            if (this.dragSource === src && this.img == null) {
                                if (this.dispatchEvent(e, "contextmenu", src)) {
                                    this.reset();
                                }
                            }
                        }, DragDropTouch.CTXMENU);
                        if (DragDropTouch.IS_PRESS_HOLD_MODE) {
                            this.pressHoldInterval = setTimeout(()=>{
                                this.isDragEnabled = true;
                                this.touchMove(e);
                            }, DragDropTouch.PRESS_HOLD_AWAIT);
                        }
                    }
                }
            }
        };
        this.touchMove = (e)=>{
            if (this.shouldCancelPressHoldMove(e)) {
                this.reset();
                return;
            }
            if (this.shouldHandleMove(e) || this.shouldHandlePressHoldMove(e)) {
                const target = this.getTarget(e);
                if (this.dispatchEvent(e, "mousemove", target)) {
                    this.lastTouch = e;
                    e.preventDefault();
                    return;
                }
                if (!this.lastTouch) {
                    this.lastTouch = e;
                    return;
                }
                const lastPointOnPage = this.getPoint(this.lastTouch, true);
                const curPointOnPage = this.getPoint(e, true);
                this.lastMovementX = curPointOnPage.x - lastPointOnPage.x;
                this.lastMovementY = curPointOnPage.y - lastPointOnPage.y;
                const Extras = {
                    movementX: this.lastMovementX,
                    movementY: this.lastMovementY
                };
                if (this.dragSource && this.img == null && this.shouldStartDragging(e)) {
                    this.dispatchEvent(e, "dragstart", this.dragSource, Extras);
                    this.createImage(e);
                    this.dispatchEvent(e, "dragenter", target, Extras);
                }
                if (this.img != null) {
                    this.lastTouch = e;
                    e.preventDefault();
                    this.dispatchEvent(e, "drag", this.dragSource, Extras);
                    if (target != this.lastTarget) {
                        this.dispatchEvent(this.lastTouch, "dragleave", this.lastTarget, Extras);
                        this.dispatchEvent(e, "dragenter", target, Extras);
                        this.lastTarget = target;
                    }
                    this.moveImage(e);
                    this.isDropZone = this.dispatchEvent(e, "dragover", target, Extras);
                }
            }
        };
        this.touchEnd = (e)=>{
            if (this.shouldHandle(e)) {
                if (this.dispatchEvent(this.lastTouch, "mouseup", e.target)) {
                    e.preventDefault();
                    return;
                }
                if (this.img == null) {
                    this.dragSource = null;
                    this.dispatchEvent(this.lastTouch, "click", e.target);
                    this.lastClick = Date.now();
                }
                this.destroyImage();
                if (this.dragSource) {
                    let Extras = {
                        movementX: this.lastMovementX,
                        movementY: this.lastMovementY
                    };
                    if (e.type.indexOf("cancel") < 0 && this.isDropZone) {
                        this.dispatchEvent(this.lastTouch, "drop", this.lastTarget, Extras);
                    }
                    this.dispatchEvent(this.lastTouch, "dragend", this.dragSource, Extras);
                    this.reset();
                }
            }
        };
        this.lastClick = 0;
        // singleton limitation
        if ("object" !== "undefined" && window.DragDropTouch) {
            throw new Error("DragDropTouch instance already created.");
        }
        // feature-detection: passive event listeners
        let supportsPassive = false;
        if (typeof document !== "undefined") {
            const passiveTest = {
                get passive () {
                    supportsPassive = true;
                    document.removeEventListener("test", emptyFunc);
                    return true;
                }
            };
            const emptyFunc = ()=>{};
            document.addEventListener("test", emptyFunc, passiveTest);
        }
        if (typeof navigator !== "undefined" && typeof document !== "undefined" && navigator.maxTouchPoints > 0) {
            const options = supportsPassive ? {
                passive: false,
                capture: false
            } : false;
            document.addEventListener("touchstart", this.touchStart, options);
            document.addEventListener("touchmove", this.touchMove, options);
            document.addEventListener("touchend", this.touchEnd);
            document.addEventListener("touchcancel", this.touchEnd);
        }
    }
}
DragDropTouch.copyProps = (src, dst, props)=>{
    for (const p of props){
        dst[p] = src[p];
    }
};
DragDropTouch.copyStyle = (src, dst)=>{
    DragDropTouch.REMOVE_ATTRS.forEach((attr)=>{
        dst.removeAttribute(attr);
    });
    if (src instanceof HTMLCanvasElement) {
        const cSrc = src;
        const cDst = dst;
        cDst.width = cSrc.width;
        cDst.height = cSrc.height;
        cDst.getContext("2d").drawImage(cSrc, 0, 0);
    }
    const cs = getComputedStyle(src);
    for (const key of Object.keys(cs)){
        if (key.indexOf("transition") >= 0) continue;
        if (!isNaN(parseInt(key))) continue;
        dst.style[key] = cs[key];
    }
    dst.style.pointerEvents = "none";
    for(let i = 0; i < src.children.length; i++){
        DragDropTouch.copyStyle(src.children[i], dst.children[i]);
    }
};
/** pixels to move before drag starts */ DragDropTouch.THRESHOLD = 5;
/** drag image opacity */ DragDropTouch.OPACITY = 0.5;
/** max ms between clicks for it to be a double click */ DragDropTouch.DBLCLICK = 500;
/** min ms for a touch-and-hold to be seeking a context menu */ DragDropTouch.CTXMENU = 900;
/** indicates whether press & hold mode is used */ DragDropTouch.IS_PRESS_HOLD_MODE = false;
/** ms to wait before press & hold is detected */ DragDropTouch.PRESS_HOLD_AWAIT = 400;
/** pixels that finger might move while pressing */ DragDropTouch.PRESS_HOLD_MARGIN = 25;
/** pixels to move before drag starts after press & hold mode is activated */ DragDropTouch.PRESS_HOLD_THRESHOLD = 0;
DragDropTouch.REMOVE_ATTRS = [
    "id",
    "class",
    "style",
    "draggable"
];
DragDropTouch.KEYBOARD_PROPS = [
    "altKey",
    "ctrlKey",
    "metaKey",
    "shiftKey"
];
DragDropTouch.POINT_PROPS = [
    "pageX",
    "pageY",
    "clientX",
    "clientY",
    "screenX",
    "screenY",
    "offsetX",
    "offsetY"
];
export const useDragDropTouch = ()=>{
    useEffect(()=>{
        if ("object" !== "undefined") {
            try {
                window.DragDropTouch = new DragDropTouch();
            } catch (e) {
                // an error here probably means we're trying to instantiate this again, so just log it for reference
                console.error(e);
            }
        }
        return ()=>{
            if ("object" !== "undefined") {
                window.DragDropTouch = null;
            }
        };
    }, []);
};
