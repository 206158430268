/* __next_internal_client_entry_do_not_use__ useErrorNotification auto */ import { useTranslations } from "@packages/i18n";
import { useEffect } from "react";
import { useAlert } from "../components";
export function useErrorNotification(param) {
    let { isError, title, description } = param;
    const alert = useAlert({
        button: "Ok"
    });
    const t = useTranslations();
    useEffect(()=>{
        if (isError && !alert.visible) {
            alert.show({
                id: "error-notification",
                title: title !== null && title !== void 0 ? title : t("general_word_error"),
                description
            });
        }
    }, [
        isError
    ]);
}
