/* __next_internal_client_entry_do_not_use__ useViewedScreen auto */ import { useContext, useEffect, useState } from "react";
import { analyticsContext } from "../lib";
import { HallowAnalyticsEvent } from "../types";
export const useViewedScreen = (param)=>{
    let { screen, properties = {}, isReady = true } = param;
    const [isMounted, setIsMounted] = useState(false);
    const { track } = useContext(analyticsContext);
    useEffect(()=>{
        setIsMounted(true);
        return ()=>{
            setIsMounted(false);
        };
    }, []);
    useEffect(()=>{
        if (isMounted && isReady) track({
            event: HallowAnalyticsEvent.ViewedScreen,
            properties: {
                screen_name: screen,
                ...properties
            }
        });
    }, [
        isMounted,
        isReady
    ]);
};
