export var HallowAnalyticsEvent;
(function(HallowAnalyticsEvent) {
    HallowAnalyticsEvent["AdminAssigned"] = "Admin Assigned";
    HallowAnalyticsEvent["AudioStartDelayed"] = "Audio Start Delayed";
    HallowAnalyticsEvent["AudioStarted"] = "Audio Started";
    HallowAnalyticsEvent["BibleCompleted"] = "Bible Completed";
    HallowAnalyticsEvent["BibleStarted"] = "Bible Started";
    HallowAnalyticsEvent["ChangedBibleChapter"] = "Changed Bible Chapter";
    HallowAnalyticsEvent["ClosedShareAudioView"] = "Closed Share Audio View";
    HallowAnalyticsEvent["CollectionFavorited"] = "Collection Favorited";
    HallowAnalyticsEvent["CommunityReportResponded"] = "Community Report Responded";
    HallowAnalyticsEvent["DraggedQueueItem"] = "Dragged Queue Item";
    HallowAnalyticsEvent["DraggedTimeSlider"] = "Dragged Time Slider";
    HallowAnalyticsEvent["HeraldPrizeRedemptionClicked"] = "Herald Prize Redemption Clicked";
    HallowAnalyticsEvent["HeraldPrizeRedemptionSubmitted"] = "Herald Prize Redemption Submitted";
    HallowAnalyticsEvent["LoopChanged"] = "Loop Changed";
    HallowAnalyticsEvent["MaximizedPlayer"] = "Maximized Player";
    HallowAnalyticsEvent["MinimizedPlayer"] = "Minimized Player";
    HallowAnalyticsEvent["OpenedBibleBookMenu"] = "Opened Bible Book Menu";
    HallowAnalyticsEvent["OpenedBibleChapterMenu"] = "Opened Bible Chapter Menu";
    HallowAnalyticsEvent["PlayerBeganTrack"] = "Player Began Track";
    HallowAnalyticsEvent["PlayerDestroyed"] = "Player Destroyed";
    HallowAnalyticsEvent["PlayerEndedTrack"] = "Player Ended Track";
    HallowAnalyticsEvent["PlayerInitialized"] = "Player Initialized";
    HallowAnalyticsEvent["PurchasedGiftCard"] = "Purchased Gift Card";
    HallowAnalyticsEvent["RemovedPost"] = "Removed Post";
    HallowAnalyticsEvent["SelectedBibleTranslation"] = "Selected Bible Translation";
    HallowAnalyticsEvent["SelectedChoice"] = "Selected Choice";
    HallowAnalyticsEvent["TappedAdminDashboardTab"] = "Tapped Admin Dashboard Tab";
    HallowAnalyticsEvent["TappedAssignAsMember"] = "Tapped Assign As Member";
    HallowAnalyticsEvent["TappedAssignAsAdmin"] = "Tapped Assign As Admin";
    HallowAnalyticsEvent["TappedBack"] = "Tapped Back";
    HallowAnalyticsEvent["TappedBibleBook"] = "Tapped Bible Book";
    HallowAnalyticsEvent["TappedBibleChapter"] = "Tapped Bible Chapter";
    HallowAnalyticsEvent["TappedBibleTranslations"] = "Tapped Bible Translations";
    HallowAnalyticsEvent["TappedBuyGift"] = "Tapped Buy Gift";
    HallowAnalyticsEvent["TappedBuyNow"] = "Tapped Buy Now";
    HallowAnalyticsEvent["TappedCampaignOption"] = "Tapped Campaign Option";
    HallowAnalyticsEvent["TappedCampaignSession"] = "Tapped Campaign Session";
    HallowAnalyticsEvent["TappedCancelSubscription"] = "Tapped Cancel Subscription";
    HallowAnalyticsEvent["TappedCommunityMemberOptions"] = "Tapped Community Member Options";
    HallowAnalyticsEvent["TappedCommunityMemberProfile"] = "Tapped Community Member Profile";
    HallowAnalyticsEvent["TappedCommunityPermissionsToggle"] = "Tapped Community Permissions Toggle";
    HallowAnalyticsEvent["TappedCommunitySettings"] = "Tapped Community Settings";
    HallowAnalyticsEvent["TappedContinue"] = "Tapped Continue";
    HallowAnalyticsEvent["TappedContactHallowParishLead"] = "Tapped Contact Hallow Parish Lead";
    HallowAnalyticsEvent["TappedDashboardChartTimeframe"] = "Tapped Dashboard Chart Timeframe";
    HallowAnalyticsEvent["TappedFastForward"] = "Tapped Fast Forward";
    HallowAnalyticsEvent["TappedGroup"] = "Tapped Group";
    HallowAnalyticsEvent["TappedGroupInvite"] = "Tapped Group Invite";
    HallowAnalyticsEvent["TappedGroupMembers"] = "Tapped Group Members";
    HallowAnalyticsEvent["TappedInviteFriends"] = "Tapped Invite Friends";
    HallowAnalyticsEvent["TappedJoinCampaign"] = "Tapped Join Campaign";
    HallowAnalyticsEvent["TappedJoinChallenge"] = "Tapped Join Challenge";
    HallowAnalyticsEvent["TappedLeaveGroup"] = "Tapped Leave Group";
    HallowAnalyticsEvent["TappedMergeAccountsSendVerification"] = "Tapped Merge Accounts Send Verification";
    HallowAnalyticsEvent["TappedPause"] = "Tapped Pause";
    HallowAnalyticsEvent["TappedPaymentApplePay"] = "Tapped Apple Pay";
    HallowAnalyticsEvent["TappedPaymentGooglePay"] = "Tapped Google Pay";
    HallowAnalyticsEvent["TappedPaymentPaypal"] = "Tapped Paypal";
    HallowAnalyticsEvent["TappedPlay"] = "Tapped Play";
    HallowAnalyticsEvent["TappedPlayAll"] = "Tapped Play All";
    HallowAnalyticsEvent["TappedPlayNextSession"] = "Tapped Play Next Session";
    HallowAnalyticsEvent["TappedPlayRadioStation"] = "Tapped Play Radio Station";
    HallowAnalyticsEvent["TappedPrayTodaysSession"] = "Tapped Pray Todays Session";
    HallowAnalyticsEvent["TappedQueue"] = "Tapped Queue";
    HallowAnalyticsEvent["TappedQueueItem"] = "Tapped Queue Item";
    HallowAnalyticsEvent["TappedRefundSubscription"] = "Tapped Refund Subscription";
    HallowAnalyticsEvent["TappedReportReason"] = "Tapped Report Reason";
    HallowAnalyticsEvent["TappedRemoveCommunityMember"] = "Tapped Remove Community Member";
    HallowAnalyticsEvent["TappedRemovePost"] = "Tapped Remove Post";
    HallowAnalyticsEvent["TappedResumeSession"] = "Tapped Resume Session";
    HallowAnalyticsEvent["TappedRewind"] = "Tapped Rewind";
    HallowAnalyticsEvent["TappedSectionActionButton"] = "Tapped Section Action Button";
    HallowAnalyticsEvent["TappedSectionItem"] = "Tapped Section Item";
    HallowAnalyticsEvent["TappedSectionMoreMenu"] = "Tapped Section More Menu";
    HallowAnalyticsEvent["TappedShareCampaign"] = "Tapped Share Campaign";
    HallowAnalyticsEvent["TappedShareCampaignSessionCount"] = "Tapped Share Campaign Session Count";
    HallowAnalyticsEvent["TappedShareChallenge"] = "Tapped Share Challenge";
    HallowAnalyticsEvent["TappedShareCollection"] = "Tapped Share Collection";
    HallowAnalyticsEvent["TappedShuffle"] = "Tapped Shuffle";
    HallowAnalyticsEvent["TappedSkip"] = "Tapped Skip";
    HallowAnalyticsEvent["TappedSkipBack"] = "Tapped Skip Back";
    HallowAnalyticsEvent["TappedSkipForward"] = "Tapped Skip Forward";
    HallowAnalyticsEvent["TappedSkipIntro"] = "Tapped Skip Intro";
    HallowAnalyticsEvent["TappedSleepTimer"] = "Tapped Sleep Timer";
    HallowAnalyticsEvent["TappedTextOnScreen"] = "Tapped Text on Screen";
    HallowAnalyticsEvent["TappedTrackSettings"] = "Tapped Track Settings";
    HallowAnalyticsEvent["TappedUnlinkSmallGroup"] = "Tapped Unlink Small Group";
    HallowAnalyticsEvent["TappedWebAppNav"] = "Tapped Web App Nav";
    HallowAnalyticsEvent["TappedWebCommunity"] = "Tapped Web Community";
    HallowAnalyticsEvent["TappedWebCommunityDropdown"] = "Tapped Web Community Dropdown";
    HallowAnalyticsEvent["TimerChanged"] = "Timer Changed";
    HallowAnalyticsEvent["TimerEnded"] = "Timer Ended";
    HallowAnalyticsEvent["ViewedCancelSubscription"] = "Viewed Cancel Subscription";
    HallowAnalyticsEvent["ViewedRefundSubscription"] = "Viewed Refund Subscription";
    HallowAnalyticsEvent["ViewedScreen"] = "Viewed Screen";
    HallowAnalyticsEvent["ViewedShareChallengeDialog"] = "Viewed Share Challenge Dialog";
    HallowAnalyticsEvent["ViewedShareCollectionDialog"] = "Viewed Share Collection Dialog";
})(HallowAnalyticsEvent || (HallowAnalyticsEvent = {}));
