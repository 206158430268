import { mockDataContent } from "./mockDataContent";
export const mockDataSections = {
    name: "Sections Title",
    sections: Array.from(Array(4).keys()).map((index)=>({
            id: index,
            items: Array.from(Array(8).keys()).map((index)=>({
                    id: index,
                    reference_type: "collection",
                    reference: {
                        collection: {
                            id: index,
                            title: "Content Medium Title",
                            desc: "Content Medium Description",
                            short_desc: "Content Medium Description",
                            sessions: "10 sessions",
                            images: {
                                small: "https://d3tv5g0y9izdfo.cloudfront.net/covers/81_s.png",
                                medium: "https://d3tv5g0y9izdfo.cloudfront.net/covers/81_m.png",
                                large: "https://d3tv5g0y9izdfo.cloudfront.net/covers/81_l.png",
                                color_hex: mockDataContent.color
                            }
                        }
                    }
                })),
            item_type: "medium",
            title: "Section Title",
            type: "carousel"
        }))
};
