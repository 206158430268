import { usePlayer } from "./usePlayer";
const progressInRadians = (percentProgress)=>{
    return (360 * percentProgress - 90) * (Math.PI / 180);
};
const SVG_WIDTH = 24;
const CIRCLE_RADIUS = 9;
export const useProgressCalculation = function() {
    let { svgWidth = SVG_WIDTH, radius = CIRCLE_RADIUS } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const player = usePlayer();
    let percentProgress = 0;
    if (player) {
        percentProgress = player.currentTime / player.duration;
    }
    const theta = progressInRadians(percentProgress);
    const x1 = svgWidth / 2 + radius * Math.cos(theta);
    const y1 = svgWidth / 2 + radius * Math.sin(theta);
    return {
        arcX: x1,
        arcY: y1,
        largeArcFlag: percentProgress && percentProgress > 0.5 && percentProgress < 1 ? 1 : 0
    };
};
