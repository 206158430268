import { useState } from "react";
import { v4 as uuid } from "uuid";
import { useTransition } from "../../../../../providers";
import { fade } from "./fade";
import { shape } from "./shape";
import { cover, push } from "./slide";
import { wipe } from "./wipe";
export const useTransitionStyles = (kindProps)=>{
    const [uniqueTransitionId] = useState(uuid());
    const { transitionMs } = useTransition();
    let baseStyleXArray, enteringStyleXArray, enteredStyleXArray, exitingStyleXArray, exitedStyleXArray;
    switch(kindProps.kind){
        case "push":
            baseStyleXArray = [
                push.base(kindProps.direction)
            ];
            enteringStyleXArray = [
                push.entering(kindProps.direction)
            ];
            enteredStyleXArray = [
                push.entered(kindProps.direction)
            ];
            exitingStyleXArray = [
                push.exiting(kindProps.direction)
            ];
            exitedStyleXArray = [
                push.exited(kindProps.direction)
            ];
            break;
        case "cover":
            baseStyleXArray = [
                cover.base(kindProps.direction)
            ];
            enteringStyleXArray = [
                cover.entering(kindProps.direction)
            ];
            enteredStyleXArray = [
                cover.entered(kindProps.direction)
            ];
            exitingStyleXArray = [
                cover.exit
            ];
            exitedStyleXArray = [
                cover.exit
            ];
            break;
        case "wipe":
            baseStyleXArray = [
                wipe.base
            ];
            enteringStyleXArray = [
                wipe.entering(kindProps.angle)
            ];
            enteredStyleXArray = [
                wipe.entered(kindProps.angle)
            ];
            exitingStyleXArray = [];
            exitedStyleXArray = [];
            break;
        case "shape":
            baseStyleXArray = [];
            enteringStyleXArray = [];
            enteredStyleXArray = [];
            exitingStyleXArray = [
                shape.exiting
            ];
            exitedStyleXArray = [
                shape.exited(kindProps.shape, transitionMs, uniqueTransitionId)
            ];
            break;
        case "custom":
            var _kindProps_baseStyleXArray;
            baseStyleXArray = (_kindProps_baseStyleXArray = kindProps.baseStyleXArray) !== null && _kindProps_baseStyleXArray !== void 0 ? _kindProps_baseStyleXArray : [];
            enteringStyleXArray = kindProps.enteringStyleXArray;
            enteredStyleXArray = kindProps.enteredStyleXArray;
            exitingStyleXArray = kindProps.exitingStyleXArray;
            exitedStyleXArray = kindProps.exitedStyleXArray;
            break;
        case "fade":
        default:
            baseStyleXArray = [
                fade.base
            ];
            enteringStyleXArray = [
                fade.entering
            ];
            enteredStyleXArray = [
                fade.entered
            ];
            exitingStyleXArray = [
                fade.exiting
            ];
            exitedStyleXArray = [
                fade.exited
            ];
    }
    return [
        baseStyleXArray,
        enteringStyleXArray,
        enteredStyleXArray,
        exitingStyleXArray,
        exitedStyleXArray
    ];
};
