import { redirectToAuth, useSession } from "@packages/sdk";
import { useCallback } from "react";
import { usePlayer } from "./usePlayer";
export const useAddToQueue = (param)=>{
    let { type, id } = param;
    const player = usePlayer();
    const { status } = useSession();
    const handleClickNext = useCallback((e)=>{
        e.stopPropagation();
        if (status === "authenticated") player === null || player === void 0 ? void 0 : player.addToQueue({
            type,
            id,
            position: "next"
        });
        else redirectToAuth();
    }, [
        player,
        status
    ]);
    const handleClickLast = useCallback((e)=>{
        e.stopPropagation();
        if (status === "authenticated") player === null || player === void 0 ? void 0 : player.addToQueue({
            type,
            id,
            position: "last"
        });
        else redirectToAuth();
    }, [
        player,
        status
    ]);
    let stringKeyNext = "play_next";
    let stringKeyLast = "play_last";
    if (type === "collection") {
        stringKeyNext = "play_all_next";
        stringKeyLast = "play_all_later";
    }
    return {
        handleClickLast,
        handleClickNext,
        stringKeyNext,
        stringKeyLast
    };
};
