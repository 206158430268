/* __next_internal_client_entry_do_not_use__ useStatePromise auto */ import { useCallback, useEffect, useRef, useState } from "react";
export const useStatePromise = (initialState)=>{
    const [state, setState] = useState(initialState);
    const resolver = useRef(null);
    const _setState = useCallback((newState)=>{
        return new Promise((resolve)=>{
            setState(state);
            if (newState === state) resolve(state);
            else {
                resolver.current = resolve;
            }
        });
    }, [
        state
    ]);
    useEffect(()=>{
        var _resolver_current;
        (_resolver_current = resolver.current) === null || _resolver_current === void 0 ? void 0 : _resolver_current.call(resolver, state);
    }, [
        state
    ]);
    return [
        state,
        _setState
    ];
};
