import { FlaggedReason } from "@packages/sdk";
export const FLAG_RECORD_REASON_OPTIONS = [
    {
        labelLokaliseKey: "community_flag_harmful_behavior",
        sublabelLokaliseKey: "community_flag_harmful_behavior_subtitle",
        value: FlaggedReason.HarmfulBehavior
    },
    {
        labelLokaliseKey: "community_flag_deceptive_content",
        sublabelLokaliseKey: "community_flag_deceptive_content_subtitle",
        value: FlaggedReason.DeceptiveContent
    },
    {
        labelLokaliseKey: "community_flag_inappropriate_content",
        sublabelLokaliseKey: "community_flag_inappropriate_content_subtitle",
        value: FlaggedReason.InappropriateContent
    },
    {
        labelLokaliseKey: "community_flag_mental_health",
        sublabelLokaliseKey: "community_flag_mental_health_subtitle",
        value: FlaggedReason.MentalHealthAndSafety
    },
    {
        labelLokaliseKey: "community_flag_something_else",
        sublabelLokaliseKey: "community_flag_something_else_subtitle",
        value: FlaggedReason.SomethingElse
    }
];
