import { useQueue } from "./useQueue";
export const useCurrentQueueItem = ()=>{
    const queue = useQueue();
    var _queue_currentItem;
    const currentItem = (_queue_currentItem = queue.currentItem) !== null && _queue_currentItem !== void 0 ? _queue_currentItem : null;
    return {
        queue,
        currentItem
    };
};
