/* __next_internal_client_entry_do_not_use__ useMasterQueue auto */ import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { useDrop } from "react-aria";
import { useQueue } from "./useQueue";
export const useMasterQueue = (param)=>{
    let { queueRef } = param;
    const queue = useQueue();
    const [dragIndex, setDragIndex] = useState(null);
    const [draggedTitle, setDraggedTitle] = useState(null);
    const itemRefs = useRef({});
    useEffect(()=>{
        var _queue_upcomingQueue;
        if (!(queue === null || queue === void 0 ? void 0 : (_queue_upcomingQueue = queue.upcomingQueue) === null || _queue_upcomingQueue === void 0 ? void 0 : _queue_upcomingQueue.length)) itemRefs.current = {};
        const preexisting = (itemRefs === null || itemRefs === void 0 ? void 0 : itemRefs.current) ? {
            ...itemRefs.current
        } : {};
        const nowKeys = [];
        for (const qi of queue.upcomingQueue){
            nowKeys.push(qi.uuid);
            if (preexisting[qi.uuid]) continue;
            preexisting[qi.uuid] = createRef();
        }
        for (const k of Object.keys(preexisting)){
            if (!nowKeys.includes(k)) delete preexisting[k];
        }
        itemRefs.current = preexisting;
    }, [
        queue.upcomingQueue
    ]);
    const findMaxKey = (y)=>{
        let maxY = null, maxKey = null;
        for (const [key, ref] of Object.entries(itemRefs.current)){
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                if (rect.y < y && (maxY && rect.y > maxY || !maxY)) {
                    maxY = rect.y;
                    maxKey = key;
                }
            }
        }
        return maxKey;
    };
    const onDragStart = (title)=>{
        setDraggedTitle(title);
    };
    const onDragEnd = ()=>{
        setDraggedTitle(null);
    };
    const onDrop = useCallback(async (e)=>{
        let { y } = e;
        // react-aria's drop events (specifically) don't use the bounding client rectangle or the screen
        // position, but rather the offset position within the drop target; we know what the drop
        // target is, so we need to translate this Y we're given into one that makes sense in the
        // context of our measurements
        y += queueRef.current.getBoundingClientRect().y;
        for (const itm of e.items){
            if (itm.kind === "text") {
                const newQueue = queue.shuffled ? structuredClone(queue.upcomingQueue) : structuredClone(queue.fullQueue);
                const objStr = await itm.getText("text/json");
                const obj = JSON.parse(objStr);
                // find the last item before the target location
                const maxKey = findMaxKey(y);
                // don't make API calls for a no-change drag-and-drop
                if (maxKey === obj.uuid) return;
                const startOfUpcoming = newQueue.findIndex((qi)=>qi.uuid === queue.upcomingQueue[0].uuid);
                newQueue.splice(newQueue.findIndex((qi)=>qi.uuid === obj.uuid), 1);
                if (!maxKey) {
                    // nothing was before it
                    newQueue.splice(startOfUpcoming, 0, obj);
                } else {
                    const insert = newQueue.findIndex((qi)=>qi.uuid === maxKey) + 1;
                    // otherwise, add it AFTER the max key
                    newQueue.splice(insert, 0, obj);
                }
                if (queue.shuffled) {
                    queue.localReorder(newQueue);
                } else {
                    // always reorder locally so it updates immediately and we don't have to wait for
                    // the backend (which is slow on very long queues, like a huge music album)
                    queue.localReorder(newQueue.slice(startOfUpcoming));
                    await queue.newQueue({
                        items: newQueue.map((qi)=>({
                                type: "audio",
                                id: qi.selected_audio.id
                            }))
                    });
                }
                return;
            }
        }
    }, [
        itemRefs === null || itemRefs === void 0 ? void 0 : itemRefs.current,
        queue.shuffled,
        queue.fullQueue
    ]);
    const { dropProps, isDropTarget } = useDrop({
        onDrop,
        ref: queueRef
    });
    const onDragMove = useCallback((e)=>{
        if (isDropTarget) {
            const { y } = e;
            const maxKey = findMaxKey(y);
            // at the top
            if (!maxKey) setDragIndex(0);
            else {
                // put it after
                setDragIndex(queue.upcomingQueue.findIndex((qi)=>qi.uuid === maxKey) + 1);
            }
        } else {
            setDragIndex(null);
        }
    }, [
        itemRefs === null || itemRefs === void 0 ? void 0 : itemRefs.current,
        queue.upcomingQueue,
        isDropTarget
    ]);
    return {
        itemRefs,
        dropProps,
        queue,
        onDragMove,
        dragIndex,
        onDragStart,
        onDragEnd,
        draggedTitle
    };
};
