/* __next_internal_client_entry_do_not_use__ colorMix auto */ import { getRGBAFromColor } from "./getRGBAFromColor";
export const colorMix = (param)=>{
    let { adjustment, format, source } = param;
    const sourceColors = getRGBAFromColor({
        color: source,
        format: "array"
    });
    const adjustmentColors = getRGBAFromColor({
        color: adjustment,
        format: "array"
    });
    const nAmount = adjustmentColors[3];
    // red
    const sourceRed = sourceColors[0];
    const adjustmentRed = adjustmentColors[0];
    const r = Math.max(Math.min(sourceRed + (adjustmentRed - sourceRed) * nAmount, 255), 0);
    // green
    const sourceGreen = sourceColors[1];
    const adjustmentGreen = adjustmentColors[1];
    const g = Math.max(Math.min(sourceGreen + (adjustmentGreen - sourceGreen) * nAmount, 255), 0);
    // blue
    const sourceBlue = sourceColors[2];
    const adjustmentBlue = adjustmentColors[2];
    const b = Math.max(Math.min(sourceBlue + (adjustmentBlue - sourceBlue) * nAmount, 255), 0);
    // alpha
    const a = isNaN(sourceColors[3]) ? 1.0 : sourceColors[3];
    //   return [red, green, blue, alpha];
    switch(format){
        case "array":
            return [
                r,
                g,
                b,
                a
            ];
        case "object":
            return {
                r,
                g,
                b,
                a
            };
        default:
            return "rgba(".concat(r, ",").concat(g, ",").concat(b, ",").concat(a, ")");
    }
};
