/* __next_internal_client_entry_do_not_use__ useLocalRef auto */ import { useRef } from "react";
/**
 * This function links a forwarded ref (which is always optional) to a local ref so we can refer to it locally without
 * worrying about whether someone passed a ref to the component.
 * @param {React.ForwardedRef<any>} ref The forwarded ref made from forwardRef
 */ export const useLocalRef = (ref)=>{
    const localRef = useRef(null);
    return new Proxy(localRef, {
        set: (target, prop, value)=>{
            if (ref) {
                if (ref) {
                    ref[prop] = value;
                } else {
                    ref(value);
                }
            }
            return Reflect.set(target, prop, value);
        }
    });
};
