export const MOCK_DATA_GET_AVATARS = [
    {
        id: 1,
        url: "https://images-dev.hallow.app/avatars/1.png",
        name: "Harold on Green Background"
    },
    {
        id: 2,
        url: "https://images-dev.hallow.app/avatars/2.png",
        name: "Harold on Yellow Background"
    },
    {
        id: 3,
        url: "https://images-dev.hallow.app/avatars/3.png",
        name: "Harold on Purple Background"
    },
    {
        id: 4,
        url: "https://images-dev.hallow.app/avatars/4.png",
        name: "Harold on Blue Background"
    },
    {
        id: 5,
        url: "https://images-dev.hallow.app/avatars/5.png",
        name: "Harold on Pink Background"
    },
    {
        id: 6,
        url: "https://images-dev.hallow.app/avatars/6.png",
        name: "Harold on Grey Background"
    },
    {
        id: 7,
        url: "https://images-dev.hallow.app/avatars/7.png",
        name: "Harold on Navy Background"
    },
    {
        id: 8,
        url: "https://images-dev.hallow.app/avatars/8.png",
        name: "Harold with a bow tie"
    },
    {
        id: 9,
        url: "https://images-dev.hallow.app/avatars/9.png",
        name: "Harold with glasses"
    },
    {
        id: 10,
        url: "https://images-dev.hallow.app/avatars/10.png",
        name: "Harold with a sweatband on working out"
    },
    {
        id: 11,
        url: "https://images-dev.hallow.app/avatars/11.png",
        name: "Harold with a facemask on"
    },
    {
        id: 12,
        url: "https://images-dev.hallow.app/avatars/12.png",
        name: "Harold with a mustache"
    },
    {
        id: 13,
        url: "https://images-dev.hallow.app/avatars/13.png",
        name: "Sleeping Harold"
    },
    {
        id: 14,
        url: "https://images-dev.hallow.app/avatars/14.png",
        name: "Student Harold"
    },
    {
        id: 15,
        url: "https://images-dev.hallow.app/avatars/15.png",
        name: "Painter Harold"
    },
    {
        id: 16,
        url: "https://images-dev.hallow.app/avatars/16.png",
        name: "Harold giving the peace sign"
    },
    {
        id: 17,
        url: "https://images-dev.hallow.app/avatars/17.png",
        name: "Harold carrying the cross"
    },
    {
        id: 18,
        url: "https://images-dev.hallow.app/avatars/18.png",
        name: "Harold giving a thumbs up"
    },
    {
        id: 19,
        url: "https://images-dev.hallow.app/avatars/19.png",
        name: "Bishop Harold"
    },
    {
        id: 20,
        url: "https://images-dev.hallow.app/avatars/20.png",
        name: "Harold with a flower"
    },
    {
        id: 21,
        url: "https://images-dev.hallow.app/avatars/21.png",
        name: "Centurion Harold"
    },
    {
        id: 22,
        url: "https://images-dev.hallow.app/avatars/22.png",
        name: "Priest Harold"
    },
    {
        id: 23,
        url: "https://images-dev.hallow.app/avatars/23.png",
        name: "Nun Harold"
    },
    {
        id: 24,
        url: "https://images-dev.hallow.app/avatars/24.png",
        name: "Harold in a bathtub"
    },
    {
        id: 25,
        url: "https://images-dev.hallow.app/avatars/25.png",
        name: "Sports Fan Harold"
    },
    {
        id: 26,
        url: "https://images-dev.hallow.app/avatars/26.png",
        name: "Harold holding a heart"
    },
    {
        id: 27,
        url: "https://images-dev.hallow.app/avatars/27.png",
        name: "Chef Harold"
    },
    {
        id: 28,
        url: "https://images-dev.hallow.app/avatars/28.png",
        name: "Pirate Harold"
    },
    {
        id: 29,
        url: "https://images-dev.hallow.app/avatars/29.png",
        name: "Cowboy Harold"
    },
    {
        id: 30,
        url: "https://images-dev.hallow.app/avatars/30.png",
        name: "Artist Harold"
    },
    {
        id: 31,
        url: "https://images-dev.hallow.app/avatars/31.png",
        name: "Ghost Harold"
    },
    {
        id: 32,
        url: "https://images-dev.hallow.app/avatars/32.png",
        name: "Graduating Harold"
    },
    {
        id: 33,
        url: "https://images-dev.hallow.app/avatars/33.png",
        name: "Harold with a party hat on"
    },
    {
        id: 34,
        url: "https://images-dev.hallow.app/avatars/34.png",
        name: "Boat Captain Harold"
    },
    {
        id: 35,
        url: "https://images-dev.hallow.app/avatars/35.png",
        name: "Astronaut Harold"
    },
    {
        id: 36,
        url: "https://images-dev.hallow.app/avatars/36.png",
        name: "Harold in a pool floatie"
    },
    {
        id: 37,
        url: "https://images-dev.hallow.app/avatars/37.png",
        name: "Hallow in spring hat"
    },
    {
        id: 38,
        url: "https://images-dev.hallow.app/avatars/38.png",
        name: "Hallow in spring hat 2"
    },
    {
        id: 39,
        url: "https://images-dev.hallow.app/avatars/39.png",
        name: "Pilot Harold"
    },
    {
        id: 40,
        url: "https://images-dev.hallow.app/avatars/40.png",
        name: "Backpacking Harold"
    }
];
