export const getRGBAFromColor = (param)=>{
    let { color, format } = param;
    let rgbaArray = [
        255,
        255,
        255,
        255
    ]; // Default white with full opacity
    // hex
    if (/^#[a-fA-F0-9]{6,8}$/.test(color)) {
        const r = parseInt(color.substring(1, 3), 16);
        const g = parseInt(color.substring(3, 5), 16);
        const b = parseInt(color.substring(5, 7), 16);
        let a = 255; // Default alpha to full opacity if not specified
        if (color.length === 9) {
            // Check if alpha value is present
            a = parseInt(color.substring(7, 9), 16);
        }
        rgbaArray = [
            r,
            g,
            b,
            a / 255
        ];
    }
    // rgb(a)
    if (/^rgba?/.test(color)) {
        rgbaArray = color.split("(")[1].split(")")[0].split(",").map((c)=>parseFloat(c));
    }
    switch(format){
        case "array":
            return rgbaArray;
        case "object":
            return {
                r: rgbaArray[0],
                g: rgbaArray[1],
                b: rgbaArray[2],
                a: rgbaArray[3]
            };
        default:
            return "rgba(".concat(rgbaArray[0], ",").concat(rgbaArray[1], ",").concat(rgbaArray[2], ",").concat(rgbaArray[3], ")");
    }
};
