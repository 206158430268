/* __next_internal_client_entry_do_not_use__ themeContext auto */ import { createContext } from "react";
import { Theme } from "../types";
export const themeContext = createContext({
    setTheme: ()=>{},
    theme: Theme.LIGHT,
    fontStyle: "normal",
    setFontStyle: ()=>{},
    letterSpacing: false,
    setLetterSpacing: ()=>{},
    wordSpacing: false,
    setWordSpacing: ()=>{},
    themeContainerRef: null
});
