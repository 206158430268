export const deepMergeStyleXProps = (param)=>{
    let { object1, object2 } = param;
    return [
        object1,
        object2
    ].reduce((deepMerged, object)=>{
        Object.entries(object).forEach((param)=>{
            let [key, value] = param;
            switch(key){
                case "className":
                    const deepMergedClassName = deepMerged["className"];
                    deepMerged["className"] = "".concat(deepMergedClassName ? "".concat(deepMerged["className"], " ") : "").concat(value);
                    break;
                case "style":
                    var _deepMerged_style;
                    deepMerged["style"] = {
                        ...(_deepMerged_style = deepMerged["style"]) !== null && _deepMerged_style !== void 0 ? _deepMerged_style : {},
                        ...value
                    };
                    break;
                default:
                    deepMerged[key] = value;
            }
        });
        return deepMerged;
    });
};
